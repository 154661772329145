import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    h1: {
      fontSize: "41px",
      fontWeight: "600",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "600",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    h3: {
      fontSize: "25px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    h4: {
      fontSize: "20px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    h5: {
      fontSize: "18px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    h6: {
      fontSize: "16px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    body1: {
      fontSize: "12px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
    body2: {
      fontSize: "16px",
      fontFamily: "'Lexend Deca', sans-serif",
    },
  },
};

const themes = {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};

export default themes;
