import { UserContext } from "src/context/User";
import React, { lazy, Suspense, useContext } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
// import Layout from "./Layout";
// import Themes from "../themes/default"
// import Themes from "../themes";
// pages
import Error from "../pages/error";
import DataLoading from "./Loaders/DataLoading";

export default function App() {
  // global
  // var { isAuthenticated } = useUserState();
  var isAuthenticated = true;
  const user = useContext(UserContext);
  return (
    <HashRouter>
      {user?.isLoadingData ? (
        <DataLoading />
      ) : (
        <Suspense fallback={() => <DataLoading />}>
          <Switch>
            <Route
              exact
              path='/'
              render={() => <Redirect to='/app/landingpage' />}
            />
            <PublicRoute
              exact
              path='/'
              render={() => <Redirect to='/app/landingpage' />}
            />
            <PrivateRoute
              path='/app'
              component={lazy(() => import("./Layout"))}

              // component={Layout}
            />{" "}
            {/* <PublicRoute path='/login' component={Login} /> */}
            <Route component={Error} />
          </Switch>{" "}
        </Suspense>
      )}
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
