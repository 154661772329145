// LIVE
// const nodeURL = "https://node.satellitecrypto.tech";
// const JavaURL = "https://java.satellitecrypto.tech";

// const nodeURL = "http://172.16.1.143:1915";
// const JavaURL = "http://172.16.1.220:4050"; // local

// // // New one
// const JavaURL = "https://java.taralityplus.in";
// const nodeURL = "https://apimining.taralityplus.com";
// // // // mobiloitte
const JavaURL = "https://java-staking.mobiloitte.org";
const nodeURL = "https://notification-staking.mobiloitte.org";

// local
// const nodeURL = "http://172.16.1.172:1915";
// const nodeURL = "    172.16.1.176:1915"

const account = `${JavaURL}/account`;
const wallet = `${JavaURL}/wallet`;
const login = `${JavaURL}/auth`;
const user = `${nodeURL}/api/v1/user`;
const statics = `${nodeURL}/api/v1/static`;
const subscription = `${nodeURL}/api/v1/subscription`;
export const webURL = window.location.origin + "/#";
const apiConfig = {
  // java api
  signUpJava: `${account}/signup`,
  verifyOTP: `${account}/verify-user?token=`,
  changePassword: `${account}/change-password`,
  forGetPassword: `${account}/forget-password`,
  saveStakeDetails: `${account}/save-stake-details`,
  unStakeDetails: `${account}/unStake-details`,
  verifyOtpForWithdraw: `${account}/verify-otp-for-withdraw`,
  sendOtpForWithdraw: `${account}/send-otp-for-withdraw`,
  uploadFile: `${account}/upload-file`,
  addToken: `${wallet}/add-token`,
  addNewToken: `${wallet}/coin/admin/add-Coin`,
  getCoinList: `${wallet}/coin/get-coin-list`,
  getNewTokenList: `${wallet}/get-newtoken-list`,
  deleteNewToken: `${wallet}/delete-newtoken`,

  getTokenList: `${wallet}/get-token-list`,
  login: `${login}`,
  walletDeatils: `${wallet}/wallet/get-all-user-balance-and-coinlist`,
  livePrice: `${wallet}/wallet/get-live-price?coinName=`,

  getAddress: `${wallet}/wallet/get-address?coinName=`,
  getDeposit: `${wallet}/wallet/get-deposits`,

  staking: `${wallet}/wallet/get-staking-details`,
  updateStaking: `${wallet}/admin/hot-cold-storage/update-staking-balance`,
  mySubscriptionPlan: `${wallet}/wallet/get-user-plan-details`,
  getBalnace: `${wallet}/wallet/get-balance?coinName=`,

  getSubscriptionDetailsForUser: `${wallet}/wallet/get-subscription-details-for-user?userId=`,
  getSubscriptionDetails: `${wallet}/wallet/get-subscription-details`,

  withdraw: `${wallet}/wallet/withdraw`,
  addressFetch: `${wallet}/wallet/get-address?coinName=`,

  cancelWithdraw: `${wallet}/wallet/cancel-withdraw`,
  approveWithdraw: `${wallet}/wallet/approve-withdraw`,
  transactionHistory: `${wallet}/wallet/get-all-transaction-history-for-user`,
  transactionHistoryAdmin: `${wallet}/admin/transaction-history/get-all-transaction-history`,

  buySubscription: `${wallet}/wallet/buy-subscription`,
  getAllCoinUsingGET: `${wallet}/admin/hot-cold-storage/get-all-Storage0Detail-details`,
  filterAmountDistributionDetails: `${wallet}/admin/hot-cold-storage/filter-amount-distribution-details`,
  getUserCountByPlan: `${wallet}/admin/hot-cold-storage/get-total-user-count-for-distribution-amount?subscriptionId=`,
  distributeAmount: `${wallet}/admin/hot-cold-storage/distribute-amount-old`,

  myAccount: `${account}/my-account`,
  resetPasswordJava: `${account}/reset-password`,
  getUserCountByStatus: `${account}/admin/dashboard/getUserCountByStatus`,
  getUserDetails: `${account}/admin/user-management/filter-user-details`,
  getViewUserDetails: `${account}/admin/user-management/user-details?userId=`,

  getUserCount: `${account}/admin/dashboard/totalUserCount`,
  getAllStakingAmountCount: `${wallet}/admin/hot-cold-storage/get-allstaking-details`,
  getviewUserDetailsWallet: `${wallet}/wallet/get-user-wallet-details?userId=`,
  // getAdminEarningDetails: `${wallet}/admin/transaction-history/get-user-earning-Details`,
  totalEarnForDashboard: `${wallet}/admin/transaction-history/total-earn-for-admin`,
  getPackageDEtails: `${wallet}/admin/transaction-history/get-user-subscription?userId=`,
  getAdminEarningDetails: `${wallet}/admin/transaction-history/get-user-earning-Details-for-admin`,
  getUserPackegDEtails: `${wallet}/admin/transaction-history/get-all-package-details?subcriptionId=`,
  getUserEarn: `${wallet}/wallet/get-earning-Details-for-user`,

  connectWallet: `${wallet}/wallet/conect-wallet`,
  deleteToken: `${wallet}/delete-token`,
  // jhgjhfj
  // getCoins: `${coin}/get-coin-list`,
  signup: `${account}/signup`,
  // login: `${login}`,
  forgotPassword: `${user}/forgotPassword`,
  contactUs: `${user}/contactUs`,

  sendOTP: `${account}/resend-verify-email`,
  // verifyOTP: `${user}/verifyOTP`,
  resetPassword: `${account}/resetPassword`,
  userStatus: `${account}/admin/user-management/user-status`,

  // connectWallet: `${user}/connectWallet`,
  addPresale: `${user}/addPresale`,
  createStacking: `${user}/createStacking`,
  getSubscriptionCommissionUser: `${user}/getSubscriptionCommission`,
  subscriptionList: `${subscription}/subscriptionList`,
  editSubscription: `${subscription}/editSubscription`,
  deleteSubscription: `${subscription}/deleteSubscription`,

  viewSubscription: `${subscription}/viewSubscription/?_id=`,
  editSubscription: `${subscription}/editSubscription`,
  addSubscription: `${subscription}/addSubscription`,

  getSubscriptionCommission: `${nodeURL}/api/v1/admin/getSubscriptionCommission`,
  changeSubscriptionCommission: `${nodeURL}/api/v1/admin/changeSubscriptionCommission`,
  staticContentList: `${statics}/staticContentList`,
  viewStatics: `${statics}/viewStaticContent?type=`,
  editStaticContent: `${statics}/editStaticContent`,
};

export default apiConfig;
